import { GatsbyBrowser , navigate } from "gatsby";

import { pixelInit, pixelTrackPage } from "./src/fb-pixel";

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  try {
    pixelInit();
  } catch (e) {
    console.warn(e);
  }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  if(location.search) {
    const referenceUserCode = new URLSearchParams(location.search).get("refu");
    const referenceUser = referenceUserCode && new TextDecoder().decode(
      Uint8Array.from(atob(referenceUserCode).split("").map(x => x.charCodeAt(0)))
    );
    if(referenceUser) {
      localStorage.setItem("refu", referenceUser);
      navigate(location.pathname, { replace: true });
    }
  }

  try {
    pixelTrackPage();
  } catch (e) {
    console.warn(e);
  }
};

// Unregister pending service workers
(async () => {
  if (navigator?.serviceWorker?.getRegistrations) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }
  }
})().catch(console.warn);
